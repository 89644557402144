







































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'
import FormInput, { FormInputInstance, InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'

import { ContactApi, PersonType } from '@/api'

import { User } from '@/types'

interface InputFields {
  firstName: InputField;
  lastName: InputField;
  phoneNumber: InputField;
  emailAddress: InputField;
  question: InputField;
}

@Component({
  components: {
    FormButton,
    FormInput
  }
})
export default class ContactForm extends mixins(State) {
  subscriptionId = this.currentUser.subscription?.subscriptionId || null

  inputFields: InputFields = {
    firstName: {
      name: 'firstName',
      value: this.currentUser.employeeDetails?.firstName || '',
      required: true,
      type: 'text',
      placeholder: 'Voornaam',
      errors: []
    },

    lastName: {
      name: 'lastName',
      value: this.lastName,
      required: true,
      type: 'text',
      placeholder: 'Achternaam',
      errors: []
    },

    phoneNumber: {
      name: 'phoneNumber',
      value: this.currentUser.employeeDetails?.phonePersonal || '',
      required: true,
      type: 'text',
      placeholder: 'Telefoonnummer',
      errors: []
    },

    emailAddress: {
      name: 'emailAddress',
      value: this.currentUser.employeeDetails?.emailAddress || '',
      required: true,
      type: 'email',
      placeholder: 'E-mailadres',
      errors: []
    },

    question: {
      name: 'question',
      value: '',
      required: true,
      type: 'textarea',
      placeholder: 'Je vraag / opmerking',
      errors: []
    }
  }

  public submit () {
    this.clearErrors()
    if (!this.validate()) {
      return
    }

    const api = new ContactApi()

    api.contactSendContactForm({
      subscriptionId: this.subscriptionId,
      firstName: this.inputFields.firstName.value,
      lastName: this.inputFields.lastName.value,
      phoneNumber: this.inputFields.phoneNumber.value,
      emailAddress: this.inputFields.emailAddress.value,
      question: this.inputFields.question.value,
      personType: PersonType.Employee
    })
      .then(() => {
        this.state = this.states.COMPLETE
      })
      .catch((error) => {
        this.state = this.states.ERROR

        this.setErrors(error.response.data.errors)
      })
  }

  public setErrors (errors: []) {
    Object.entries(errors).forEach(([errorKey, errorValue]) => {
      Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
        if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
          fieldValue.errors = errorValue
        }
      })
    })
  }

  public clearErrors () {
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      fieldValue.errors = []
    })
  }

  public validate () {
    let valid = true
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      const field = (this.$refs[fieldValue.name] as FormInputInstance)
      if (Array.isArray(field)) {
        valid = field.every((inputField: FormInputInstance) => inputField.validate()) && valid
      } else {
        valid = field.validate() && valid
      }
    })
    return valid
  }

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get lastName () {
    return `${this.currentUser.employeeDetails?.middleName ? `${this.currentUser.employeeDetails?.middleName} ` : ''}${this.currentUser.employeeDetails?.lastName}`
  }
}
